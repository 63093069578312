<template>
  <v-container fluid>
    <AError :api="apiGetErrorLog" />

    <transition name="fade">
      <v-alert
        dense
        border="left"
        type="warning"
        class="text-pre-wrap"
        v-if="alertError.length > 0"
        ><span
          class="d-block"
          v-for="(error, index) in alertError"
          :key="index"
          >{{ error }}</span
        ></v-alert
      >
    </transition>

    <!--BOC:[contents]-->
    <v-card>
      <v-toolbar :color="HRDC_BLUE_THEME_COLOR">
        <v-btn icon class="hidden-xs-only text-white" @click="redirectBack()">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>

        <v-toolbar-title v-if="!loadingDialog">
          <div class="d-flex align-center text-white">
            {{ model.add.name }}
          </div>
        </v-toolbar-title>
      </v-toolbar>

      <div>
        <v-row
          class="d-flex justify-space-between pa-5"
          cols="12"
          lg="12"
          md="12"
          sm="12"
        >
          <v-col cols="12" lg="6" sm="6" md="6">
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="errorLogDate"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  dense
                  filled
                  v-model="errorLogDate"
                  label="Error Log Date"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="errorLogDate" no-title scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu = false">
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.menu.save(errorLogDate)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>

          <v-col cols="12" sm="6" md="6">
            <v-btn
              x-large
              outlined
              :color="HRDC_BLUE_THEME_COLOR"
              @click="getErrorLog()"
            >
              Generate
              <v-icon right> mdi-file-document-refresh </v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <div class="pa-5">
          <v-data-table
            :headers="headers"
            :items="logDatas"
            :items-per-page="10"
            class="elevation-1"
            hide-default-footer
          >
            <template v-slot:item.err="{ item }">
              <div v-html="item.err"></div>
            </template>
          </v-data-table>
        </div>
      </div>
    </v-card>

    <v-dialog v-model="loadingDialog" persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Loading
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
//BOC:[api]
import Api from "@/objects/api";
//EOC
//BOC:[model]
import Model from "@/objects/model";
//EOC
//BOC:[service]
import Service from "@/objects/service";
//EOC
//BOC:[globalData]
import HrdcData from "@/services/hrdc_einvoicing/objects/globalData";
//EOC
//BOC:[mapState]
import { mapState } from "vuex";
//EOC

// import moment from "moment";

export default {
  components: {},
  computed: {
    ...mapState({
      auth: (state) => state.auth.data,
    }),
  },
  props: ["params"],
  emits: ["toggleTaxRatePage"],
  data: () => ({
    //BOC:[apiGetErrorLog]
    apiGetErrorLog: new Api(),
    //EOC
    //BOC:[model]
    model: new Model(),
    //EOC
    //BOC:[service]
    service: new Service(),
    //EOC
    //BOC:[hrdcData]
    hrdcData: new HrdcData(),
    //EOC
    alertError: [],
    errorLogDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu: false,
    modal: false,
    formData: {},
    errorField: {
      applicationNo: null,
    },
    loadingDialog: false,
    btnDisabled: false,
    headers: [
      {
        text: "Time",
        align: "start",
        sortable: false,
        value: "time",
      },
      { text: "PID", value: "pid", sortable: false },
      { text: "Hostname", value: "hostname", sortable: false },
      { text: "Error", value: "err", sortable: false },
    ],
    logDatas: [],
  }),
  watch: {
    //
  },
  async created() {
    // this.loadingDialog = !this.loadingDialog;
    this.formData.applicationNo = this.$store.state.hrdc_einvoicing.data.applicationUuid;
    //BOC:[model]
    this.model.getByKey(this.params.modelKey);
    //EOC
    //BOC:[service]
    if (this.model.serviceKey) this.service.set("key", this.model.serviceKey);
    //EOC

    // Assign apiDetails by mapping repository.
    const apiDetails = {
      apiGetErrorLog: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/settings/error_log/get-error-log`,
        method: "get",
      },
    };

    for (const api in apiDetails) {
      if (apiDetails[api].url !== undefined) {
        this[api].setUrl(apiDetails[api].url);
      }
      if (apiDetails[api].method !== undefined) {
        this[api].setMethod(apiDetails[api].method);
      }
      if (apiDetails[api].params !== undefined) {
        this[api].setParams(apiDetails[api].params);
      }
    }

    //EOC
    this.stages = await this.hrdcData.allStages;
    this.rolesAssignation(this.auth.roles);
  },
  mounted() {},
  methods: {
    clearErrorMsg() {
      for (let error in this.errorField) {
        this.errorField[error] = null;
      }
    },
    showErrorMessage(response) {
      for (const key in response.data) {
        this.errorField[key] = null;
        this.errorField[key] = response.data[key];
      }
    },
    redirectBack() {
      let route = "HrdcEinvoicingSettings";
      if (this.params.backTo) {
        route = this.params.backTo;
      }

      this.$router.push({
        name: route,
        params: {
          modelKey: this.model.key,
          backTo: "",
        },
      });
    },
    getErrorLog() {
      this.logDatas = [];

      this.apiGetErrorLog.setQuery(`errorLogDate=${this.errorLogDate}`);
      this.apiGetErrorLog.setCallbackCompleted((response) => {
        try {
          const { status, data } = response;

          if (!status) {
            this.$store.dispatch("showMessage", {
              message: data,
              messageType: "error",
              timeout: 2000,
            });

            return;
          }

          if (status) {
            if (data) {
              for (const item of data) {
                if (item) {
                  const { time, pid, hostname, err } = item;

                  // Prepare error stack details.
                  let errorStack = `
                    <b>type</b>: ${err.type}<br/><br/>
                    <b>message</b>: ${err.message}<br/><br/>
                    <b>stack</b>: ${err.message}<br/>
                  `;

                  this.logDatas.push({
                    time,
                    pid,
                    hostname,
                    err: errorStack,
                  });
                }
              }
            }
          }
        } catch (err) {
          console.log(err);
        }
      });
      this.apiGetErrorLog.fetch();
    },
  },
};
</script>
